import icon from "../img/icons/pdf_icon.png";
import { useEffect } from "react";
const Drinks = ({ t }) => {
  useEffect(() => {
    setTimeout(() => {
      const link = document.getElementById("link");
      if (!link) return;
      link.click();
    }, 4000);
  }, []);
  return (
    <div id="drinks-page">
      <iframe
        style={{ width: 1, height: 1 }}
        src={`${process.env.PUBLIC_URL}/drinks_kf_2024_juni.pdf`}></iframe>
      <a
        id="link"
        download={true}
        style={{ width: 1, height: 1 }}
        href={`${process.env.PUBLIC_URL}/drinks_kf_2024_juni.pdf`}></a>
      <div className="drinks-page-wrapper">
        <div className="head-wrap">
          <h1>{t("drinks.h")}</h1>
          <div className="btn-wrap">
            <a
              target="_blank"
              href={`${process.env.PUBLIC_URL}/drinks_kf_2024_juni.pdf`}
              className="btn">
              <span>{t("drinks.btn")}</span>
              <img src={icon} alt="" />
            </a>
          </div>
        </div>
        {/* <object
        className="pdf-viewer"
        data={`${process.env.PUBLIC_URL}/drinks_kf_2024_juni.pdf`}></object> */}
      </div>
    </div>
  );
};
export default Drinks;
